import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import axios from "axios";
function App() {
  const [busNumberLists, setBusNumberLists] = useState();
  const api = "http://api.hyosungvina.com/GetBus";
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(api, { headers: { Authorization: `Bearer ${token}` } })

      .then((res) => {
        setBusNumberLists(res.data && res.data.items);
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);
  // console.log(busNumberLists, "bus");
  return (
    <>
      {busNumberLists &&
        busNumberLists.map((busNumberList, i) => (
          <Route path={`/${busNumberList.numberPlate}`} component={Home} />
        ))}
      {/* <Route path={`/60B03327`} component={Home} />
      <Route path={`/60B04405`} component={Home} />
      <Route path={`/51F01278`} component={Home} />
      <Route path={`/60B03195`} component={Home} /> */}
    </>
  );
}

export default App;
