import React from "react";
import Form from "../Component/Form";
const Home = () => {
  return (
    <div>
      <Form />
    </div>
  );
};
export default Home;
