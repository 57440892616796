import React, { useState, useEffect } from "react";
import img5k from "../assets/images/img5k.jpg";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import $ from "jquery";
import classnames from "classnames";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import mainLogo from "../assets/images/logo.png";
import { FilePresent } from "@mui/icons-material";
$(document).ready(function () {
  $(window).keydown(function (event) {
    if (event.keyCode === 13) {
      $("#confirm-id").click();
      event.preventDefault();
    }
  });
});

const Form = (props) => {
  //Get Date Time
  var today = new Date();

  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var moment = require("moment"); // require
  var time = moment().format("h:mm:ss a");
  const [activeTab, setActiveTab] = useState("1");
  const { empCard, fullName } = props;
  const [form, setForm] = useState();
  const [isConfirm, setIsConfirm] = useState(false);
  const [empInfos, setEmpInfos] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false);
  const [wrongId, setWrongId] = useState(true);
  const [empActive, setEmpActive] = useState(true);
  const [guestActive, setGuestActive] = useState(!empActive);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [isGuest, setIsGuest] = useState(false);
  let pathname = window.location.pathname;

  function removeCharacter(str) {
    let tmp = str.split("");
    return tmp.slice(1).join("");
  }

  let busNumber = removeCharacter(pathname);

  localStorage.setItem("busNumber", busNumber);

  // Get Employees Info
  const api = `http://api.hyosungjobs.com/GetEmployeeInfor?empId=${
    form && form.empId
  }`;
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(api, { headers: { Authorization: `Bearer ${token}` } })

      .then((res) => {
        if (
          Number.isNaN(form && form.seatNum) ||
          (form && form.seatNum == "")
        ) {
          setEmpInfos("");
        } else {
          setEmpInfos(res.data && res.data.items);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  }, [form]);

  //Handle Open Confirm
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e) => {
    if (
      form &&
      form.seatNum &&
      form.seatNum.length !== " " &&
      form.seatNum <= 45 &&
      empInfos &&
      empInfos.length > 0
    ) {
      setOpen(true);
      empInfos &&
        empInfos.map((emp, i) => {
          localStorage.setItem("code", emp.code);
          localStorage.setItem("fullName", emp.empName);
          localStorage.setItem("phone", emp.phoneNumber);
          localStorage.setItem("organization", emp.organization);
          localStorage.setItem("dept", emp.department);
          localStorage.setItem("plant", emp.plant);
          localStorage.setItem("section", emp.section);
          localStorage.setItem("seatNum", form.seatNum);
          const getName = localStorage.getItem("fullName");
          const getPhone = localStorage.getItem("phone");
          const getCompany = localStorage.getItem("organization");
          const getPlant = localStorage.getItem("plant");
          const getDepartment = localStorage.getItem("dept");
          const getSection = localStorage.getItem("section");
          const getBusNumber = localStorage.getItem("busNumber");
          const getSeatNum = localStorage.getItem("seatNum");
          setForm({
            ...form,
            fullName: getName,
            numberPlate: getBusNumber,
            seatNum: parseInt(getSeatNum),
            phoneNumber: getPhone,
            organization: getCompany,
            plant: getPlant,
            department: getDepartment,
            section: getSection,
            [e.target.name]: e.target.value,
          });
        });
    } else {
      alert(
        "Wrong ID or empty seat number/Sai mã nhân viên hoặc số ghế"
      );
    }
  };
  //Get Info local
  const getCode = localStorage.getItem("code");
  const getName = localStorage.getItem("fullName");
  const getPhone = localStorage.getItem("phone");
  const getCompany = localStorage.getItem("organization");
  const getPlant = localStorage.getItem("plant");
  const getDepartment = localStorage.getItem("dept");
  const getSection = localStorage.getItem("section");
  const getBusNumber = localStorage.getItem("busNumber");
  const getSeatNum = localStorage.getItem("seatNum");
  useEffect(() => {
    if (getCode && getCode.length > 0) {
      setForm({ ...form, empId: getCode });
    }
  }, []);
  const handleGuestClickOpen = (e) => {
    setOpen(true);
    localStorage.setItem("code", form.empId);
    localStorage.setItem("fullName", form.fullName);
    localStorage.setItem("phone", form.phoneNumber);
    localStorage.setItem("organization", form.organization);
    localStorage.setItem("seatNum", form.seatNum);
    const getName = localStorage.getItem("fullName");
    const getPhone = localStorage.getItem("phone");
    const getCompany = localStorage.getItem("organization");
    const getBusNumber = localStorage.getItem("busNumber");
    const getSeatNum = localStorage.getItem("seatNum");
    setForm({
      ...form,
      numberPlate: getBusNumber,
      seatNum: parseInt(getSeatNum),
      [e.target.name]: e.target.value,
    });
  };
  const handleGuestChange = (e) => {
    setForm({
      ...form,
      numberPlate: getBusNumber,
      seatNum: parseInt(form && form.seatNum),
      [e.target.name]: e.target.value,
    });
    console.log(form, "guestform");
  };
  const handleClose = () => {
    setOpen(false);
  };
  //Handle Change
  const handleChange = (e) => {
    setForm({
      ...form,
      fullName: getName,
      numberPlate: getBusNumber,
      seatNum: parseInt(form && form.seatNum),
      phoneNumber: getPhone,
      organization: getCompany,
      plant: getPlant,
      department: getDepartment,
      section: getSection,
      [e.target.name]: e.target.value,
    });
    console.log(form, "form");
  };
  //handle Submit
  const handleSubmit = (e) => {
    if (empInfos && empInfos.map((emp, i) => emp.empName !== "")) {
      $.ajax({
        type: "POST",
        url: "http://api.hyosungjobs.com/RollUp",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        data: JSON.stringify({
          ...form,
        }),
        success: function (result) {
          setSuccessMessage(true);
        },
      });

      e.preventDefault();
    } else {
      alert(
        "Check your employee id or seat number (Kiểm tra lại mã nhân viên hoặc số ghế)"
      );
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="container form form-employees">
        <img id="main-logo" src={mainLogo} alt="" className="logo" />

        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames(
                  { active: activeTab === "1" },
                  "border-right border-info"
                )}
                onClick={() => {
                  toggle("1");
                  setGuestActive(false);
                  setEmpActive(true);
                  setForm({ ...form, isGuest: false });
                }}
              >
                {empActive && empActive === true ? (
                  <span>
                    <i className="fas fa-address-card text-info pr-2"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fas fa-address-card pr-2"></i>
                  </span>
                )}
                <b>EMPLOYEE</b>{" "}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { active: activeTab === "2" },
                  "border-right border-success"
                )}
                onClick={() => {
                  toggle("2");
                  localStorage.clear();
                  setGuestActive(true);
                  setEmpActive(false);
                  setForm({ ...form, isGuest: true });
                }}
              >
                {" "}
                {guestActive && guestActive === true ? (
                  <span>
                    <i className="fas fa-user text-success pr-2"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fas fa-user  pr-2"></i>
                  </span>
                )}
                <b>GUEST</b>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <div className="row">
                    <div className="col-xs-12 col-md-12">
                      <div className="auth-wrapper">
                        <div className="auth-content">
                          <div className="card">
                            <div className="row align-items-center ">
                              <div className="col-md-12">
                                <div className="card-body">
                                  <div
                                    className="loading"
                                    id="loginLoading"
                                  ></div>
                                  {successMessage && successMessage === true ? (
                                    <>
                                      <div className="alert alert-success mt-5">
                                        <strong>Submit Success !</strong> <br />
                                        <strong>
                                          Gửi Thông Tin Thành Công
                                        </strong>
                                      </div>

                                      <div className="imageAfterSuccess">
                                        <img src={img5k} />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <h5
                                        className="text-center text-primary pb-2 fw-bold mx-3 mb-0"
                                        style={{
                                          fontFamily: "quadrat",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Input Your Employee ID
                                      </h5>
                                      <h5
                                        className="text-center text-primary fw-bold mx-3 mb-0"
                                        style={{
                                          fontFamily: "quadrat",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        (Nhập Mã Nhân Viên)
                                      </h5>
                                      <form onSubmit={handleSubmit}>
                                        <div className="divider d-flex align-items-center my-2"></div>
                                        {/* Email input */}
                                        <div className=" mb-4 input-border ">
                                          <input
                                            name="empId"
                                            className="form-control form-control-lg"
                                            placeholder="ID/Mã Nhân Viên"
                                            onChange={handleChange}
                                            defaultValue={localStorage.getItem(
                                              "code"
                                            )}
                                            value={form && form.empId}
                                            required
                                          />{" "}
                                          {/* {wrongId && wrongId === true ? (
                                            <small className="text-left text-danger">
                                              Nhập chính xác mã nhân viên (Ex:
                                              vn55210730)
                                            </small>
                                          ) : (
                                            ""
                                          )} */}
                                        </div>{" "}
                                        <div className=" mb-4 input-border ">
                                          <input
                                            type="number"
                                            name="seatNum"
                                            className="form-control form-control-lg width-67"
                                            placeholder="Seat Number/ Số Ghế"
                                            onChange={handleChange}
                                            value={form && form.seatNum}
                                            required
                                          />{" "}
                                        </div>
                                        <Button
                                          id="confirm-id"
                                          variant="contained"
                                          onClick={handleClickOpen}
                                        >
                                          Confirm/ Xác Nhận
                                        </Button>
                                        {empInfos &&
                                          empInfos.map((empInfo, i) => (
                                            <>
                                              <div className="mt-2 d-flex justify-content-between border border-success rounded p-2 empInfo">
                                                <h5 className="text-primary">
                                                  Name/Tên: {"  "}
                                                  <span
                                                    className="text-dark"
                                                    key={i}
                                                  >
                                                    {empInfo.empName}
                                                  </span>
                                                </h5>
                                              </div>
                                              <div className="d-flex justify-content-between border border-success rounded p-2 empInfo">
                                                <h5 className="text-primary">
                                                  Phone/SĐT:{" "}
                                                  <span className="text-dark">
                                                    {empInfo.phoneNumber}
                                                  </span>
                                                </h5>
                                              </div>
                                              <div className="d-flex justify-content-between border border-success rounded p-2 empInfo">
                                                <h5 className="text-primary">
                                                  Company/Công ty:{" "}
                                                  <span className="text-dark">
                                                    {empInfo.organization}
                                                  </span>
                                                </h5>
                                              </div>
                                              <div className="d-flex justify-content-between border border-success rounded p-2 empInfo">
                                                <h5 className="text-primary">
                                                  Plant/Nhà máy:{" "}
                                                  <span className="text-dark">
                                                    {empInfo.plant}
                                                  </span>
                                                </h5>
                                              </div>
                                              <div className="d-flex justify-content-between border border-success rounded p-2  empInfo">
                                                <h5 className="text-primary">
                                                  Department/Bộ phận:{" "}
                                                  <span className="text-dark">
                                                    {empInfo.department}
                                                  </span>
                                                </h5>
                                              </div>
                                              <div className="d-flex justify-content-between border border-success rounded p-2 empInfo">
                                                <h5 className="text-primary">
                                                  Section/Phòng:{" "}
                                                  <span className="text-dark">
                                                    {empInfo.section}
                                                  </span>
                                                </h5>
                                              </div>{" "}
                                              <div className="d-flex justify-content-between border border-danger  p-2  empInfo">
                                                <h5>
                                                  <span>
                                                    {" "}
                                                    Date/Ngày: {date}
                                                  </span>
                                                </h5>
                                              </div>
                                              <div className="d-flex justify-content-between border border-danger p-2  empInfo">
                                                <h5>
                                                  <span> Time/Giờ: {time}</span>
                                                </h5>
                                              </div>
                                              <div className="d-flex justify-content-between border border-danger  p-2 empInfo">
                                                <h5>
                                                  Number Plate/Số Xe:{" "}
                                                  <span>
                                                    {localStorage.getItem(
                                                      "busNumber"
                                                    )}
                                                  </span>
                                                </h5>
                                              </div>{" "}
                                              <div className="d-flex justify-content-between border border-danger  p-2 empInfo">
                                                <h5>
                                                  Seat Number/Số Ghế:{" "}
                                                  <span>
                                                    {form && form.seatNum}
                                                  </span>
                                                </h5>
                                              </div>{" "}
                                              <div className="text-lg-start mt-4 pt-2">
                                                {/* <button
                                                  type="submit"
                                                  className="btn-primary btn-lg rounded"
                                                  style={{
                                                    paddingLeft: "2.5rem",
                                                    paddingRight: "2.5rem",
                                                  }}
                                                >
                                                  Xác Nhận
                                                </button> */}

                                                <Dialog
                                                  open={open}
                                                  onClose={handleClose}
                                                  aria-labelledby="alert-dialog-title"
                                                  aria-describedby="alert-dialog-description"
                                                >
                                                  <DialogTitle id="alert-dialog-title">
                                                    {"Verify Your Data To Save"}
                                                  </DialogTitle>
                                                  <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                      Kiểm tra thông tin trước
                                                      khi lưu
                                                    </DialogContentText>
                                                  </DialogContent>
                                                  <DialogActions>
                                                    <Button
                                                      onClick={handleClose}
                                                    >
                                                      Hủy (Cancel)
                                                    </Button>
                                                    <Button
                                                      id="agree"
                                                      onClick={handleSubmit}
                                                      autoFocus
                                                    >
                                                      Đồng Ý (Agree)
                                                    </Button>
                                                  </DialogActions>
                                                </Dialog>
                                              </div>
                                            </>
                                          ))}{" "}
                                        {/* {(form && form.empId.includes("h")) ||
                                        (form && form.empId.includes("H")) ? (
                                          <Button
                                            id="confirm-id"
                                            variant="contained"
                                            onClick={handleClickOpen}
                                          >
                                            Confirm
                                          </Button>
                                        ) : (
                                          ""
                                        )} */}
                                      </form>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="mt-1">
                <h6>Note/Ghi chú: </h6>
                <small>- Employee ID must not have space </small>
                <br />
                <small>- Mã nhân viên không được có khoảng trắng</small>
                <br />
                <small>
                  <b>Ex/Ví dụ:</b> vn55210730
                </small>
              </div>
            </TabPane>

            <TabPane tabId="2">
              <div className="row">
                <div className="col-xs-12 col-md-12">
                  <div className="auth-wrapper">
                    <div className="auth-content">
                      <div className="card">
                        <div className="row align-items-center text-center">
                          <div className="col-md-12">
                            <div className="card-body">
                              <div className="loading" id="loginLoading"></div>
                              {successMessage && successMessage === true ? (
                                <>
                                  <div className="alert alert-success mt-5">
                                    <strong>Submit Success !</strong> <br />
                                    <strong>Gửi Thông Tin Thành Công</strong>
                                  </div>
                                  <div className="imageAfterSuccess">
                                    <img src={img5k} />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <h5
                                    className="text-center text-primary pb-2 fw-bold mx-3 mb-0"
                                    style={{
                                      fontFamily: "quadrat",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Input Your Infomation
                                  </h5>
                                  <h5
                                    className="text-center input-info text-primary fw-bold mx-3 mb-0"
                                    style={{
                                      fontFamily: "quadrat",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    (Nhập Thông Tin Cá Nhân)
                                  </h5>
                                  <form onSubmit={handleSubmit}>
                                    <div className="divider d-flex align-items-center my-2"></div>
                                    <div className=" mb-4 input-border ">
                                      <input
                                        type="number"
                                        name="seatNum"
                                        className="form-control form-control-lg"
                                        placeholder="Seat Num/ Số Ghế"
                                        onChange={handleGuestChange}
                                        required
                                      />
                                    </div>
                                    {/* CMND input */}
                                    <div className=" mb-4 input-border ">
                                      <input
                                        name="empId"
                                        className="form-control form-control-lg"
                                        placeholder="IdentityCard/ CMND"
                                        onChange={handleGuestChange}
                                        required
                                      />
                                    </div>
                                    {/* Name input */}
                                    <div className=" mb-4 input-border ">
                                      <input
                                        name="fullName"
                                        className="form-control form-control-lg"
                                        placeholder="Full name/ Họ tên"
                                        onChange={handleGuestChange}
                                        value={form && form.fullName}
                                        required
                                      />
                                    </div>
                                    {/* Phone input */}
                                    <div className="mb-3 input-border">
                                      <input
                                        type="text"
                                        id="form3Example4"
                                        className="form-control form-control-lg"
                                        placeholder="Phone/ Số điện thoại"
                                        name="phoneNumber"
                                        value={form && form.phoneNumber}
                                        onChange={handleGuestChange}
                                        required
                                      />
                                    </div>
                                    {/* Company input */}
                                    <div className="mb-3 input-border">
                                      <input
                                        type="text"
                                        id="form3Example4"
                                        className="form-control form-control-lg"
                                        placeholder="Company/ Tên công ty"
                                        name="organization"
                                        value={form && form.organization}
                                        onChange={handleGuestChange}
                                      />
                                    </div>{" "}
                                    <div className="d-flex justify-content-between border border-danger p-2  empInfo">
                                      <h5>
                                        <span> Date/Ngày: {date}</span>
                                      </h5>
                                    </div>
                                    <div className="d-flex justify-content-between border border-danger  p-2  empInfo">
                                      <h5>
                                        <span> Time/Giờ: {time}</span>
                                      </h5>
                                    </div>
                                    <div className="d-flex justify-content-between border border-danger  p-2  empInfo">
                                      <h5>
                                        Number Plate/Số Xe:{" "}
                                        <span>
                                          {localStorage.getItem("busNumber")}
                                        </span>
                                      </h5>
                                    </div>{" "}
                                    <div className="d-flex justify-content-between border border-danger  p-2 empInfo">
                                      <h5>
                                        Seat Number/Số Ghế:{" "}
                                        <span>{form && form.seatNum}</span>
                                      </h5>
                                    </div>
                                    <div className="text-lg-start mt-4 pt-2">
                                      <Button
                                        id="confirm-id"
                                        variant="contained"
                                        onClick={handleGuestClickOpen}
                                      >
                                        Confirm/ Xác Nhận
                                      </Button>
                                      <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                      >
                                        <DialogTitle id="alert-dialog-title">
                                          {"Verify Your Data To Save"}
                                        </DialogTitle>
                                        <DialogContent>
                                          <DialogContentText id="alert-dialog-description">
                                            Kiểm tra thông tin trước khi lưu
                                          </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                          <Button onClick={handleClose}>
                                            Hủy (Cancel)
                                          </Button>
                                          <Button
                                            onClick={handleSubmit}
                                            autoFocus
                                          >
                                            Đồng Ý (Agree)
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </div>
                                  </form>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>

          <div className="text-center mt-2">
            <span className="">
              <i>Copyright 2021 © </i>

              <a href="http://hyosungvina.com">
                {" "}
                <i>Hyosung Vietnam</i>
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Form;
